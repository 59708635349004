import { ApiTypes } from "openfoodfac-ts";

export class ProductsRepository {
    baseUrl: string = "https://world.openfoodfacts.org";
    static RESULTS_PER_PAGE: number = 10;


    /**
     * Find gluten free products with search term
     * @param searchTerm 
     * @param page 
     * @returns Promise<ApiTypes.ProductsResponse>
     */
    findProductsBySearchTerm(
        searchTerm: string,
        page = 1,
    ): Promise<ApiTypes.ProductsResponse> {
        const parsedTerm = encodeURIComponent(searchTerm);

        return fetch(
            `${this.baseUrl}/cgi/search.pl?search_terms=${parsedTerm}&page=${page}&search_simple=1&action=process&tagtype_0=labels&tag_contains_0=contains&tag_0=gluten-free&json=1&page_size=${ProductsRepository.RESULTS_PER_PAGE}`
        ).then(response => response.json());
    }


    async findProductByBarcode(barcode: string): Promise<ApiTypes.Product | null> {
        return fetch(`${this.baseUrl}/api/v0/product/${barcode}`).then(response => response.json());
    }
}