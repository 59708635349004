import { IonAvatar, IonBadge, IonButton, IonContent, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonSearchbar, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { OpenFoodFactsApi } from 'openfoodfac-ts';
import { Product, ProductsResponse } from 'openfoodfac-ts/dist/OpenFoodFactsApi/types';
import './Tab1.css';
import { ProductsRepository } from '../repository/products-repository';
import { stringify } from 'querystring';


const Tab1: React.FC = () => {

    const [products, setProducts] = useState<Product[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [searchState, setSearchState] = useState<{ search: string, page: number }>({ search: "", page: 1 })
    const productsRepository: ProductsRepository = new ProductsRepository();



    // Load Items
    const loadItems = async () => {
        if (searchState.search) {
            setShowLoading(true);
            const products: ProductsResponse = await productsRepository.findProductsBySearchTerm(searchState.search, searchState.page);
            setProducts(products.products);
            setShowLoading(false);
        }
    }


    const loadMore = () => {
        setSearchState({ search: searchState.search, page: searchState.page + 1 })
    }



    // Initial state, load data
    // Listen search term change, to reload data
    useEffect(() => {
        loadItems();
    }, [searchState]);



    // Component
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Trouver un produit</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonSearchbar placeholder='Cherchez un produit' value={searchState.search} onIonChange={e => { setSearchState({ search: e.detail.value!, page: 1 }) }}>
                    <IonSpinner hidden={!showLoading} className="search-spinner" name="crescent" />

                </IonSearchbar>

                <IonList>
                    {products.map((p, index) =>

                        <IonItem key={index} routerLink="/tab2">
                            <IonAvatar slot="start">
                                <img src={p.image_url} />
                            </IonAvatar>
                            <IonLabel>{p.product_name}</IonLabel>


                            <ul className="store-list">
                                {p.stores_tags?.map((st, stIndex) => <li key={stIndex}><IonBadge color="warning">{st}</IonBadge></li>)}
                            </ul>

                        </IonItem>
                    )}

                    {(products.length > 0 && products.length == ProductsRepository.RESULTS_PER_PAGE) && <IonItem><IonButton onClick={loadMore} color="success">Show more</IonButton></IonItem>}
                </IonList>

            </IonContent>
        </IonPage>
    );
};

export default Tab1;

