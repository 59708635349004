import { IonContent, IonHeader, IonPage, IonRouterLink, IonTitle, IonToolbar } from '@ionic/react';
import './Tab2.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { LatLngExpression, Map } from 'leaflet';
import { Link } from 'react-router-dom';



export interface CustomMarker {
    latlng: LatLngExpression;
    name: string;
}



const Tab2: React.FC = () => {


    // Refresh map
    const mapCreated = (map: Map) => {
        setTimeout(() => {
            map?.invalidateSize();
        }, 500);
    }


    const position: LatLngExpression = [47.218102, -1.552800];
    const markers: CustomMarker[] = [
        { latlng: [47.218102, -1.552800], name: "Carrefour" },
        { latlng: [47.218102, -1.572800], name: "Leclerc" },
        { latlng: [47.24102, -1.572800], name: "Magasin-U" },
        { latlng: [47.18102, -1.572800], name: "Lidl" },
    ];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Carte</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Tab 2</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <MapContainer
                    whenCreated={mapCreated}

                    center={position} zoom={12}>

                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {markers.map((m, index) => <Marker key={index} position={m.latlng}>
                        <Popup>
                            {m.name}
                        </Popup>
                    </Marker>)}
                </MapContainer>


            </IonContent>
        </IonPage>
    );
};

export default Tab2;
